<template>
  <div class="register">
    <div class="register-wrap content_area">
      <h2>
        {{ $t("RegisterInfo") }}
      </h2>
      <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
      >
        <el-form-item
            :label="$t('name')"
            prop="name"
            :rules="[{ required: true, message: ' ', trigger: 'blur' }]"
        >
          <el-input size="small" v-model="ruleForm.name"></el-input>
        </el-form-item>
        <el-form-item
            :label="$t('gender')"
            prop="gender"
            class="gender-wrap"
            :rules="[{ required: true, message: ' ', trigger: 'blur' }]"
        >
          <el-radio-group v-model="ruleForm.gender" size="small">
            <el-radio-button class="male" :class="{ LOCALE }" label="1">{{
                $t("male")
              }}
            </el-radio-button>
            <el-radio-button class="female" :class="{ LOCALE }" label="2">{{
                $t("female")
              }}
            </el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item
            :label="$t('position')"
            prop="position"
            :rules="[{ required: true, message: ' ', trigger: 'blur' }]"
        >
          <el-input size="small" v-model="ruleForm.position"></el-input>
        </el-form-item>
        <el-form-item :label="$t('phone')" prop="tel">
          <el-input
              size="small"
              v-model="ruleForm.tel"
              :disabled="mobileDisabled"
          >
            <el-select
                style="width: 100px"
                v-model="ruleForm.areaCode"
                popper-class="registerAreaCodeList"
                slot="prepend"
                filterable
                :disabled="mobileDisabled"
            >
              <el-option
                  v-for="(item, index) in areaCodeList"
                  :key="item.area_code + index + ''"
                  :value="item.area_code"
                  :title="item.country | areacodeAddressFormat(LOCALE)"
              >
                <p class="area_code">{{ item.area_code }}</p>
                <p class="country textOverflow">
                  {{ item.country | areacodeAddressFormat(LOCALE) }}
                </p>
              </el-option>
            </el-select>
          </el-input>
        </el-form-item>
        <el-form-item :label="$t('email')" prop="email">
          <el-input
              :disabled="emailDisabled"
              size="small"
              v-model="ruleForm.email"
          ></el-input>
        </el-form-item>
        <el-button
            :loading="loading"
            type="primary"
            size="small"
            class="enrollBut"
            @click="submitForm('ruleForm')"
        >{{ $t("meetingEnrollFast") }}
        </el-button>
      </el-form>
    </div>
  </div>
</template>

<script>
import {getAreaCodeListByLocale} from "@/baseUtils/AddressConfig";
import {priorFormat} from "@/basePlugins/filters";
import {validateEmail, validateTelephone} from "~/baseUtils/validate";
import {_decode} from "@/basePlugins/base64";

export default {
  data() {
    return {
      ruleForm: {
        name: null,
        gender: null,
        position: null,
        tel: null,
        areaCode: "+86",
        email: null,
      },
      rules: {
        email: [
          {
            required: true,
            validator: async (rule, value, callback) => {
              if (!value) {
                return callback(new Error(" "));
              } else {
                if (validateEmail(value)) {
                  callback();
                } else {
                  return callback(new Error(this.$t("wrongEmailFormat")));
                }
              }
            },
            trigger: "blur",
          },
        ],
        tel: [
          {
            required: true,
            validator: async (rule, value, callback) => {
              if (this.$store.state.baseStore.userInfo.mobile) {
                callback();
              } else {
                if (!value) {
                  return callback(new Error(" "));
                } else {
                  if (this.ruleForm.areaCode == "+86") {
                    if (validateTelephone(value)) {
                      callback();
                    } else {
                      return callback(this.$t("wrongTelephoneFormat"));
                    }
                  } else {
                    callback();
                  }
                }
              }
            },
            trigger: "blur",
          },
        ],
      },
      areaCodeList: [],
      loading: false,
      apply_type: null,
      company_id: null,
      meeting_id: null,
      user_id: null,
      mobileDisabled: false,
      emailDisabled: false,
    };
  },
  created() {
    this.user_id = this.$store.state.baseStore.userInfo.id;
    this.meeting_id = this.MEETING_ID;
    let data = this.$store.state.baseStore.userInfo;
    if (data.name_en || data.name_zh) {
      this.ruleForm.name = priorFormat(data.name_en, data.name_zh, this.LOCALE);
    }
    this.ruleForm.gender = data.sex;
    if (data.job_title_en || data.job_title_zh) {
      this.ruleForm.position = priorFormat(
          data.job_title_en,
          data.job_title_zh,
          this.LOCALE
      );
    }
    if (data.mobile) {
      this.ruleForm.tel = data.mobile;
      this.mobileDisabled = true;
    }

    if (data.area_code) {
      this.ruleForm.areaCode = data.area_code;
    }
    if (data.email) {
      this.ruleForm.email = data.email;
      this.emailDisabled = true;
    }
  },
  mounted() {
    this.updateUserAndEnrollInfo()
    this.areaCodeList = getAreaCodeListByLocale(this.LOCALE);
  },
  methods: {
    //更新下用户信息和报名信息
    async updateUserAndEnrollInfo() {
      let res = await this.$store.dispatch("baseStore/baseSign_getUserInfo", {
        user_id: this.USER_INFO.id
      })
      let params = {
        meeting_id: this.$store.state.meeting_id,
        user_id: this.USER_INFO.id,
        company_id: this.USER_INFO.company_id,
      };
      let result = await this.$store.dispatch(
          "baseStore/baseSign_getMeetingEnrollInfo",
          params
      );
      let data = await this.$store.dispatch('baseStore/baseSign_getUserAuth', {
        user_id: this.USER_INFO.id
      })
      this.apply_type = this.ENROLL_INFO.apply_type
      this.company_id = this.USER_INFO.company_id
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitFormFn();
        } else {
          return false;
        }
      });
    },
    async submitFormFn() {
      this.loading = true;
      let params = {};
      params.meeting_id = this.meeting_id;
      params.user_id = this.user_id;
      params.apply_type = this.apply_type;

      if (this.LOCALE === "en") {
        params.name_en = this.ruleForm.name;
        params.job_title_en = this.ruleForm.position;
      } else {
        params.name_cn = this.ruleForm.name;
        params.job_title_zh = this.ruleForm.position;
      }
      params.sex = this.ruleForm.gender;
      params.area_code = this.ruleForm.areaCode;
      params.mobile = this.ruleForm.tel;
      params.email = this.ruleForm.email;
      params.company_id = this.company_id;
      if (this.apply_type === 4) {
        params.status = 1;
        params.audit_status = 1;
      } else {
        params.status = 0;
        params.audit_status = 0;
      }
      try {
        let res = await this.$store.dispatch(
            "register/userJoinMeeting",
            params
        );

        if (res.success) {
          this.loading = false;
          this.goToSuccess();
        }
      } catch (e) {
        this.loading = false;
      }
    },
    async goToSuccess() {
      this.updateUserAndEnrollInfo()
      this.$router.push({
        path: "/registerSuccess",
        query: {
          parameter: this._encode({
            apply_type: this.apply_type,
          }),
        },
      });
    },
  },
};
</script>

<style scoped lang="less">
.register {
  padding: 24px 0;
  background: #f6f7f9;
  .register-wrap {
    padding: 40px 420px;
    background: #fff;
    h2 {
      font-weight: 600;
      font-size: 16px;
      margin: 0 0 10px 45px;
    }
  }

  .enrollBut {
    width: 300px;
    margin: 10px auto 0;
    display: block;
    position: relative;
    left: 25px;
  }

  .gender-wrap {
    /deep/ .el-radio-button__inner {
      padding: 0;
      width: 154px;
      height: 32px;
      line-height: 32px;
      border-radius: 4px;
      border: 1px solid #dcdfe6;
    }

    .male {
      margin-right: 52px;
    }

    .female.is-active {
      /deep/ .el-radio-button__inner {
        box-shadow: none;
      }
    }
  }
}
</style>
